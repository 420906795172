import { observer } from 'mobx-react';
import React, { createContext, FC, useContext, useEffect } from 'react';
import { ILocalization } from './localization/ILocalization';
import { localization } from './localization/Localization';
import { colorTheme } from './theme/ColorTheme';
import { IColorTheme } from './theme/IColorTheme';
import { ThemeProvider } from "@mui/material";
import { muiTheme } from "./theme/MuiTheme";

export const UIContext = createContext<IColorTheme & ILocalization>({} as any);

export const useUiContext = () => { return useContext(UIContext) };

interface IProps {
    children: React.ReactNode;
}

export const UIProvider: FC<IProps> = observer(({ children }) => {

    useEffect(() => {
        document.body.dir = localization.isRtL ? 'rtl' : 'ltr';
    }, [localization.isRtL]);

    const value = {
        isRtL: localization.isRtL,
        locales: localization.locales,
        colors: colorTheme.colors,
        setTheme: colorTheme.setTheme,
        theme: colorTheme.theme,
        locale: localization.locale,
        setLocale: localization.setLocale,
        t: localization.t,
    };

    return (
        <UIContext.Provider value={value}>
            <ThemeProvider theme={muiTheme}>
                {children}
            </ThemeProvider>
        </UIContext.Provider>
    );
});
