import { MobXRepository } from "src/repository/MobXRepository";

interface IContentEditorModel {
    selectedText: string;
    updatedText: string;
    isContentUpdate: boolean;
}

class ContentEditorModel implements IContentEditorModel {
    private isContentUpdateRepository = new MobXRepository<boolean>(false);
    private selectedTextRepository = new MobXRepository<string>('');
    private updatedTextRepository = new MobXRepository<string>('');

    get selectedText() {
        return this.selectedTextRepository.data || '';
    }

    set selectedText(data: string) {
        this.selectedTextRepository.save(data);
    }

    get updatedText() {
        return this.updatedTextRepository.data || '';
    }

    set updatedText(data: string) {
        this.updatedTextRepository.save(data);
    }

    get isContentUpdate() {
        return this.isContentUpdateRepository.data || false;
    }

    set isContentUpdate(data: boolean) {
        this.isContentUpdateRepository.save(data);
    }

    clear = () => {
        this.isContentUpdate = false;
        this.selectedText = '';
        this.updatedText = '';
    }

}

export const contentEditorModel = new ContentEditorModel();
