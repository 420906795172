import { IStorage } from '.';

class LocalStoreStorage implements IStorage {

    cleanAll = async (services: object) => {
        try {
            if (typeof services === 'object' && services) {
                Object.keys(services).forEach(async key => await localStorage.removeItem(key));
            }
        } catch (error) {
            console.warn('AsyncStoreStorage -> cleanAll: ', error);
        }
    }

    get = (service: string = '') => {
        try {
            let payload = localStorage.getItem(service) as any;
            if (payload) {
                payload = JSON.parse(payload);
            }
            return payload;
        } catch (error) {
            console.warn('AsyncStoreStorage -> get: ', error);
            return null;
        }
    }

    set = (service: string = '', payload: object | string | number) => {
        try {
            const payloadJSON = JSON.stringify(payload)
            localStorage.setItem(service, payloadJSON);
            return true;
        } catch (error) {
            console.warn('AsyncStoreStorage -> set: ', error);
            return false;
        }
    }

    remove = async (service: string = '') => {
        try {
            localStorage.removeItem(service);
            return true;
        } catch (error) {
            console.warn('AsyncStoreStorage -> remove: ', error);
            return false;
        }
    }

}

export const storage = new LocalStoreStorage();
