import { MobXRepository } from "src/repository/MobXRepository";
import { ICourseListItem } from "./ICourseListItem";

interface ICourseModel {
    courseMMForExport: number | null;
    courses: ICourseListItem[];
    exportedCourseMM: string | null;
}

class CourseModel implements ICourseModel {
    private coursesRepository = new MobXRepository<ICourseListItem[]>([]);
    private courseMMForExportRepository = new MobXRepository<number | null>(null);
    private exportedCourseMMRepository = new MobXRepository<string | null>(null);

    get courses() {
        return this.coursesRepository.data ?? [];
    }

    set courses(data: ICourseListItem[]) {
        this.coursesRepository.save(data);
    }

    get courseMMForExport() {
        return this.courseMMForExportRepository.data;
    }

    set courseMMForExport(data: number | null) {
        this.courseMMForExportRepository.save(data);
    }

    get exportedCourseMM() {
        return this.exportedCourseMMRepository.data;
    }

    set exportedCourseMM(data: string | null) {
        this.exportedCourseMMRepository.save(data);
    }

    clear = () => {
        this.courseMMForExport = null;
        this.exportedCourseMM = null;
        this.courses = [];
    }

}

export const courseModel = new CourseModel();
