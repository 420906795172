import { MobXRepository } from "src/repository/MobXRepository";

export interface IUpdatingSentenceModel {
    readonly selectedText: string;
    setSelectedText: (data: string) => void;
    readonly showUpdatingButtons: boolean;
    setShowUpdatingButtons: (data: boolean) => void;
    readonly showCommentModal: boolean;
    setShowCommentModal: (data: boolean) => void;
    readonly isClueShown: boolean;
    setIsClueShown: (data: boolean) => void;
}

class UpdatingSentenceModel implements IUpdatingSentenceModel {
    private isClueShownRepository = new MobXRepository(false);
    private selectedTextRepository = new MobXRepository('');
    private showUpdatingButtonsRepository = new MobXRepository(false);
    private showCommentModalRepository = new MobXRepository(false);

    get isClueShown() {
        return this.isClueShownRepository.data ?? false;
    }

    setIsClueShown = (data: boolean) => {
        this.isClueShownRepository.save(data);
    }

    get selectedText() {
        return this.selectedTextRepository.data ?? '';
    }

    setSelectedText = (data: string) => {
        this.selectedTextRepository.save(data);
    }

    get showUpdatingButtons() {
        return this.showUpdatingButtonsRepository.data ?? false;
    }

    setShowUpdatingButtons = (data: boolean) => {
        this.showUpdatingButtonsRepository.save(data);
    }

    get showCommentModal() {
        return this.showCommentModalRepository.data ?? false;
    }

    setShowCommentModal = (data: boolean) => {
        this.showCommentModalRepository.save(data);
    }

    clear = () => {
        this.setIsClueShown(false)
        this.setSelectedText('');
        this.setShowUpdatingButtons(false);
        this.setShowCommentModal(false);
    }

}

export const updatingSentenceModel = new UpdatingSentenceModel();
