import { MobXRepository } from 'src/repository/MobXRepository';
import { IQuiz } from './IQuiz';
import { storage } from "src/libraries/storage";

const initialQuiz: IQuiz = {
    lessonOrder: 0,
    lessonKey: 0,
    title: '',
    type: 'SINGLE_CHOICE',
    description: '',
    questions: [],
}

interface IQuizModel {
    questionAmount: number;
    quizzesList: IQuiz[];
    chosenQuiz: IQuiz | null;
}

class QuizModel implements IQuizModel {
    private quizzesListRepository = new MobXRepository<IQuiz[]>([]);
    private chosenQuizRepository = new MobXRepository<IQuiz | null>(null);
    private questionAmountRepository = new MobXRepository(1);

    constructor() {
        this.onLoad();
    }

    private onLoad = () => {
        const data = storage.get('quizzes');
        data && this.quizzesListRepository.save(data);
    }

    private onPersist = (data: IQuiz[]) => {
        storage.set('quizzes', data);
    }

    get chosenQuiz() {
        return this.chosenQuizRepository.data;
    }

    set chosenQuiz(data: IQuiz | null) {
        this.chosenQuizRepository.save(data);
    }

    get quizzesList() {
        return this.quizzesListRepository.data ?? [];
    }

    set quizzesList(data: IQuiz[]) {
        this.quizzesListRepository.save(data);
    }

    get questionAmount() {
        return this.questionAmountRepository.data || 1;
    }

    set questionAmount(data: number) {
        this.questionAmountRepository.save(data);
    }

    deleteQuiz(quiz: IQuiz) {
        const quizzes = this.quizzesList.filter(q => q.lessonKey !== quiz.lessonKey);
        this.quizzesList = quizzes;
        this.onPersist(quizzes);
    }

    updateQuiz(quiz: IQuiz) {
        const quizzes = [...this.quizzesList];
        const index = quizzes.findIndex(q => q.lessonKey === quiz.lessonKey);
        if (index === -1) {
            this.quizzesList = [...this.quizzesList, quiz];
        } else {
            quizzes[index] = quiz;
            this.quizzesList = quizzes;
            this.onPersist(quizzes);
        }
    }

    createQuiz(lessonKey: number, lessonOrder: number, title: string) {
        const quiz = { ...initialQuiz, lessonKey, lessonOrder, title };
        this.chosenQuiz = quiz;
    }

    clear = () => {
        this.chosenQuiz = null;
        this.questionAmount = 1;
        this.quizzesList = [];
    }

}

export const quizModel = new QuizModel();