export const translations = {
    en: {
        metaminderRegistered: 'Metaminder registered?',
        metaminderRegisteredDescription: 'Already registered in Metaminder LMS as owner and your account is verified? Then just click on the sign in button below.',
        modalSignInTitle: 'Register or Sign In',
        modalSignInCaption_1: 'To save all your courses and edit',
        modalSignInCaption_2: 'To place your course on the MetaMinder platform and teach students',
        modalSignInCaption_3: 'To create a Chatbot and add it to your course',
        unsplash_by_using_images_from_unsplash_you_agree_to_their: "By using images from Unsplash, you agree to their",
        unsplash_license: "License",
        unsplash_and: "and",
        unsplash_terms_of_service: "Terms of Service",
        goTo: 'Go',
        courseSuccessfulExported: 'Course successful exported',
        goToCourse: 'Explore your course on MetaMinder',
        courseExported: 'Course exported',
        metaminderInstructionExport_1: 'Register in Metaminder',
        metaminderInstructionExport_2: 'Confirm your email',
        metaminderInstructionExport_3: 'After registration, create a company. Only in this case will export be available',
        aboutMM: 'About Metaminder',
        registrationClue: 'Register in the menu at the top right in order to save your courses and to create an AI-chatbot based on the course!',
        websiteMM: 'Website Metaminder',
        registrationMM: 'Registration Metaminder',
        whatIsMetaminder: 'What is MetaMinder?',
        metaminderDescription: 'Don’t know how to code? No worries. Our Course Builder lets you create a course perfectly suited for both browsers and mobile apps without the need for any prior coding knowledge.',
        metaminderInstruction_1: 'Register on the platform',
        metaminderInstruction_2: 'Fill in information about your company or experience',
        metaminderInstruction_3: 'Export the course from the AI constructor to the MetaMinder platform with one click',
        metaminderInstruction_4: 'Edit courses on the platform, add games, homework and tests to them',
        metaminderInstruction_5: 'Invite students to the course',
        metaminderInfo: 'Metaminder info',
        more: 'More',
        metaminder: 'Metaminder',
        metaminderBanner: ' is your hosting platform for your courses',
        exportToMetaminder: 'Export to Metaminder',
        tryNow: 'Try now',
        myCourses: 'My courses',
        burgerSignIbSignUp: 'Sign in / Sign up',
        findPhoto: 'Find photo',
        on: 'on',
        emptyListPhotos: 'No images found, try entering another query',
        download: 'Download',
        lessonArray: ['lesson', 'lessons', 'lessons'],
        photos: 'Photos',
        enterQuery: 'Enter a query',
        delete: 'Delete',
        loadMore: 'Load more',
        courseSavedSuccessfully: 'Course saved successfully',
        not_valid_login_data: 'Invalid email or password',
        addImage: 'Add image',
        emptyListCourses: 'No courses found, try entering another query',
        searchCoursePlaceholder: 'Search courses',
        saveChanges: 'Save changes',
        chatBotInfo_1: 'Go to my courses using the menu.',
        chatBotInfo_2: 'Click on the Chatbot button.',
        chatBotInfo_3: 'Share the link to the chatbot with your students or install it on your website.',
        chatBotInfoTitle: 'Created your course already? Then install a chatbot',
        chatBotInfo: 'Chat bot / info',
        apiKey: 'API key',
        copyApiKey: 'Copy this API key and replace it in the script on your site',
        script: 'Script',
        copyChatbotScript: 'Copy this script and paste it into your site',
        tryChatBot: 'Try chatbot now',
        chatBotDescription: 'If you have already built a course in our Course Creator, you can incorporate a chatbot for your students with course content in it. If you have a website to host your course, follow the instructions to quickly install the bot on your site!',
        chatBotTitle: 'Created your course already? Then install a chatbot',
        chatBot: 'Chat bot',
        goToBot: 'Metadialog bot',
        createNewCourse: 'Create new course',
        success: 'Success',
        recentChanges: 'Recent changes',
        cancelButton: 'Cancel',
        next: 'Next',
        guestDescription: 'If you log in as a guest you will not be able to save your courses',
        logIn: 'Sign in',
        logOut: 'Sign out',
        slideGuidCaption_1: 'Fill out the course information',
        slideGuidCaption_2: 'Create a course structure with AI',
        slideGuidCaption_3: 'Create lesson content with AI',
        slideGuidCaption_4: 'Create tests based on lesson material',
        slideGuidCaption_5: 'Your course is ready, you can download it as a PDF',
        emailRequired: 'Email is required',
        passwordRequired: 'Password is required',
        confirmPasswordRequired: 'Confirm password is required',
        user_exist: 'User with this email already exists',
        passwordsNotMatch: 'Passwords do not match',
        passwordLengthError: 'Password must be at least 8 characters long',
        notValidEmail: 'Not valid email',
        policyAuth_1: 'By continuing, you agree to our',
        policyAuth_2: 'Terms of Service',
        policyAuth_3: 'and acknowledge that you have read our',
        policyAuth_4: 'Privacy Policy',
        policyAuth_5: 'to learn how we collect, use, and share your data.',
        signIn: 'Sign in',
        continueAsGuest: 'Continue as guest',
        signUp: 'Sign up',
        conformPassword: 'Confirm password',
        enterPassword: 'Enter password',
        enterEmail: 'Enter email',
        howManyLessons: 'Select the minimum number of lessons',
        example: 'Example',
        minutesArray: ['minute', 'minutes', 'minutes'],
        hoursArray: ['hour', 'hours', 'hours'],
        save: 'Save',
        sendSusses: 'Thank you for your feedback! We will review it and get back to you within 48 hours.',
        send: 'Send',
        sendUsMessage: 'Send us a message',
        helpCenterDDescription: 'Describe your problem or question and our team will get back to you via email.',
        fullName: 'Full name',
        yourEmail: 'Your email',
        subjectOfYourRequest: 'Subject of your request',
        describeYourSubject: 'Describe your subject matter',
        describeProblem: 'Describe your problem',
        yourMessage: 'Your message goes here...',
        termsOfUse: 'Terms of use',
        privacyPolicy: 'Privacy policy',
        helpCenter: 'Help center',
        language: 'Language',
        pickCorrectAnswer: 'Pick the correct answer',
        clueCourseTopic: 'How to make Neapolitan pizza at home.',
        clueCourseAudience: 'Women 25-30 years old, without professional culinary education.',
        clueCourseDescription: 'Course outcome: students will learn how to cook 3 types of Neapolitan pizza in a conventional oven right at home. \nThe course should include but not be limited to the following topics: History of pizza, peculiarities of Neapolitan pizza.\nThe course should include practical tips, advice and homework.',
        questionArray: ['question', 'questions ', 'questions '],
        duration: 'Duration',
        quiz: 'Test',
        updateTest: 'Edit test',
        deleteTest: 'Delete test',
        complete: 'Complete',
        addAnswer: 'Add answer',
        generateTest: 'Create test',
        numberOfQuestion: 'Number of questions',
        SINGLE_CHOICE: 'Single choice',
        MULTI_CHOICE: 'Multiple choice',
        quizForLesson: 'Test for lesson',
        addTest: 'Add test',
        lessonClue: 'Select the text you want to regenerate',
        courseLinkTitle: 'Follow the link to \ncreate new courses',
        courseLink: 'AI course builder',
        coursePlan: 'Course plan',
        buttonCancel: 'Cancel',
        apply: 'Apply',
        regenerateCommentPlaceholder: 'For example: Give real-world examples in the lesson of successful design solutions for companies like Apple, Amazon, etc.',
        regenerateCommentTitle: 'Write a comment that should be taken into account to correct the content',
        comment: 'Consider comment',
        courseLanguage: 'Course language',
        stopGeneration: 'Stop generation',
        replace: 'Replace',
        lessonOutline: 'Lesson outline',
        generateLessonContent: 'Create lesson',
        tryAgain: 'Regenerate',
        courseNameTitle: 'Course name',
        courseDescriptionTitle: 'Course description',
        courseDescription: 'Describe what your course will be about?',
        courseDescriptionPlaceholder: 'Tell us what your course will be about',
        courseName: 'Enter course name',
        courseNamePlaceholder: 'What is the name of your course?',
        courseDuration: 'How many hours should your course be?',
        continue: 'Continue',
        addNewLesson: 'Add new lesson',
        lesson: 'Lesson',
        generateLessonOutline: 'Generate lesson outline',
        hours: 'hours',
        addCourseInformation: 'Add course information',
        editCourse: 'Edit course',
        generateLessons: 'Generate lessons',
        goToStart: 'Go to start',
        makeShorter: 'Make shorter',
        makeLonger: "Make longer",
        regenerate: 'Regenerate',
        completeAndSavePdf: 'Complete and save pdf',
        returnToHomepage: 'Return to homepage',
        whoIsYourAudience: 'Write who is your audience?',
        audiencePlaceholder: 'Tell us about the target audience of course',
        cancel: 'Return previous',
        enterYourText: 'Enter your text',
        goBack: 'Go back',
        courseDurationPdf: 'Course duration',
        lessonDurationPdf: 'Lesson duration',
        addOutline: 'Add outline',
    },
    ru: {
        metaminderRegistered: 'Зарегистрированы в Metaminder?',
        metaminderRegisteredDescription: 'Уже зарегистрированы в Metaminder LMS как владелец и ваша учетная запись подтверждена? Тогда просто нажмите на кнопку авторизации ниже.',
        modalSignInTitle: 'Зарегистрируйтесь или войдите',
        modalSignInCaption_1: 'Чтобы сохранить все свои курсы и редактировать',
        modalSignInCaption_2: 'Чтобы разместить свой курс на платформе MetaMinder и обучать студентов',
        modalSignInCaption_3: 'Чтобы создать чат-бота и добавить его в свой курс',
        unsplash_by_using_images_from_unsplash_you_agree_to_their: "Используя изображения из Unsplash, вы соглашаетесь с их",
        unsplash_license: "Лицензией",
        unsplash_and: "и",
        unsplash_terms_of_service: "Условиями Обслуживания",
        goTo: 'Перейти',
        courseSuccessfulExported: 'Курс успешно экспортирован',
        goToCourse: 'Изучите свой курс на MetaMinder',
        courseExported: 'Курс экспортирован',
        metaminderInstructionExport_1: 'Зарегистрируйтесь на платформе MetaMinder',
        metaminderInstructionExport_2: 'Подтвердите свой email',
        metaminderInstructionExport_3: 'После регистрации создайте компанию. Только в этом случае экспорт будет доступен',
        aboutMM: 'О Metaminder',
        registrationClue: 'Зарегистрируйтесь в меню справа вверху, чтобы сохранять созданные курсы и создать своего AI-чатбота по курсу!',
        websiteMM: 'Веб-сайт Metaminder',
        registrationMM: 'Регистрация Metaminder',
        whatIsMetaminder: 'Что такое MetaMinder?',
        metaminderDescription: 'Не знаете, как кодировать? Не волнуйтесь. Наш Course Builder позволяет создавать курс, идеально подходящий как для браузеров, так и для мобильных приложений, без необходимости в каких-либо предварительных знаниях по кодированию.',
        metaminderInstruction_1: 'Зарегистрируйтесь на платформе',
        metaminderInstruction_2: 'Заполните информацию о вашей компании или опыте',
        metaminderInstruction_3: 'Экспортируйте курс из конструктора AI на платформу MetaMinder одним нажатием',
        metaminderInstruction_4: 'Редактируйте курсы на платформе, добавляйте в них игры, домашние задания и тесты',
        metaminderInstruction_5: 'Приглашайте студентов на курс',
        metaminderInfo: 'Metaminder информация',
        more: 'Еще',
        metaminder: 'Metaminder',
        metaminderBanner: ' - это ваша платформа для размещения курсов',
        exportToMetaminder: 'Экспортировать в Metaminder',
        tryNow: 'Попробовать сейчас',
        myCourses: 'Мои курсы',
        burgerSignIbSignUp: 'Вход / Регистрация',
        findPhoto: 'Найти фото',
        on: 'на',
        emptyListPhotos: 'Изображения не найдены, попробуйте ввести другой запрос',
        download: 'Скачать',
        lessonArray: ['урок', 'урока', 'уроков'],
        photos: 'Фотографии',
        enterQuery: 'Введите запрос',
        delete: 'Удалить',
        loadMore: 'Загрузить еще',
        courseSavedSuccessfully: 'Курс успешно сохранен',
        not_valid_login_data: 'Неверный email или пароль',
        addImage: 'Добавить изображение',
        emptyListCourses: 'Курсы не найдены, попробуйте ввести другой запрос',
        searchCoursePlaceholder: 'Поиск по курсам',
        saveChanges: 'Сохранить изменения',
        chatBotInfo_1: 'Перейдите в мои курсы с помощью меню.',
        chatBotInfo_2: 'Нажмите на кнопку Чат-бот.',
        chatBotInfo_3: 'Поделитесь ссылкой на чат-бот со своими учениками или установите его себе на сайт.',
        chatBotInfoTitle: 'Уже создали свой курс? Тогда установите чат-бота',
        chatBotInfo: 'Чат-бот / информация',
        apiKey: 'API ключ',
        copyApiKey: 'Скопируйте этот API-ключ и замените его в скрипте на своем сайте',
        script: 'Скрипт',
        copyChatbotScript: 'Скопируйте этот скрипт и вставьте его на свой сайт',
        tryChatBot: "Попробовать чат-бот сейчас",
        chatBotDescription: 'Теперь вы можете общаться с чат-ботом по своему курсу. Попробуйте его прямо сейчас по ссылке ниже (и поделитесь со своими учениками). А если у вас есть веб-сайт для размещения курса, следуйте инструкциям, чтобы быстро установить бота на свой сайт!',
        chatBotTitle: 'Попробуйте чат-бот по своему курсу!',
        chatBot: 'Чат-бот',
        goToBot: 'Metadialog бот',
        createNewCourse: 'Создать новый курс',
        success: 'Успешно',
        recentChanges: 'Последние изменения',
        cancelButton: 'Отмена',
        next: 'Далее',
        guestDescription: 'Если вы войдете как гость, вы не сможете сохранить свои курсы',
        logIn: 'Войти',
        logOut: 'Выйти',
        slideGuidCaption_1: 'Заполните информацию о курсе',
        slideGuidCaption_2: 'Создайте структуру курса с помощью ИИ',
        slideGuidCaption_3: 'Создайте контент урока с помощью ИИ',
        slideGuidCaption_4: 'Создайте тесты на основе материала урока',
        slideGuidCaption_5: 'Скачайте готовый курс в формате PDF',
        emailRequired: 'Email обязателен',
        passwordRequired: 'Пароль обязателен',
        confirmPasswordRequired: 'Подтверждение пароля обязательно',
        user_exist: 'Пользователь с таким email уже существует',
        passwordsNotMatch: 'Пароли не совпадают',
        passwordLengthError: 'Пароль должен быть не менее 8 символов',
        notValidEmail: 'Неверный email',
        policyAuth_1: 'Продолжая, вы соглашаетесь с нашими',
        policyAuth_2: 'Условиями обслуживания',
        policyAuth_3: 'и подтверждаете, что вы прочитали',
        policyAuth_4: 'Политику конфиденциальности',
        policyAuth_5: 'чтобы узнать, как мы собираем, используем и передаем ваши данные.',
        signIn: 'Войти',
        continueAsGuest: 'Продолжить как гость',
        signUp: 'Зарегистрироваться',
        conformPassword: 'Подтвердите пароль',
        enterPassword: 'Введите пароль',
        enterEmail: 'Введите email',
        howManyLessons: 'Выберите минимальное количество уроков',
        example: 'Пример',
        minutesArray: ['минута', 'минуты', 'минут'],
        hoursArray: ['час', 'часа', 'часов'],
        save: 'Сохранить',
        sendSusses: 'Спасибо за ваш отзыв! Мы рассмотрим его и ответим в течение 48 часов.',
        send: 'Отправить',
        sendUsMessage: 'Отправьте нам сообщение',
        helpCenterDDescription: 'Опишите вашу проблему или вопрос, и наша команда свяжется с вами по электронной почте.',
        fullName: 'Полное имя',
        yourEmail: 'Ваша электронная почта',
        subjectOfYourRequest: 'Тема вашего запроса',
        describeYourSubject: 'Опишите вашу тему',
        describeProblem: 'Опишите вашу проблему',
        yourMessage: 'Ваше сообщение здесь...',
        termsOfUse: 'Условия использования',
        privacyPolicy: 'Политика конфиденциальности',
        helpCenter: 'Техподдержка',
        language: 'Язык',
        deleteTest: 'Удалить тест',
        pickCorrectAnswer: 'Выберите правильный ответ',
        clueCourseTopic: 'Как приготовить неаполитанскую пиццу дома.',
        clueCourseAudience: 'Женщины 25-30 лет, без профессионального кулинарного образования.',
        clueCourseDescription: 'Результат прохождения курса: ученики научатся готовить 3 варианта неаполитанской пиццы в обычной духовке прямо у себя дома. \nКурс должен содержать следующие темы, но не ограничиваться ими: История пиццы, особенности неаполитанской пиццы. \nКурс должен включать практические советы, рекомендации и домашние задания. ',
        questionArray: ['вопрос', 'вопроса', 'вопросов'],
        duration: 'Продолжительность',
        quiz: 'Тест',
        updateTest: 'Обновить тест',
        complete: 'Завершить',
        addAnswer: 'Добавить ответ',
        generateTest: 'Создать тест',
        numberOfQuestion: 'Количество вопросов',
        SINGLE_CHOICE: 'Один вариант ответа',
        MULTI_CHOICE: 'Несколько вариантов ответа',
        quizForLesson: 'Тест для урока',
        addTest: 'Добавить тест',
        lessonClue: 'Выделите текст, который хотите перегенерировать',
        courseLinkTitle: 'Переходите по ссылке для \nсоздания новых курсов',
        courseLink: 'AI конструктор курсов',
        coursePlan: 'План курса',
        buttonCancel: 'Отменить',
        apply: 'Применить',
        regenerateCommentPlaceholder: 'Например: В уроке давайте рассмотрим примеры успешных дизайн-решений для компаний Apple, Amazon и т.д.',
        regenerateCommentTitle: 'Напишите комментарий, который должен быть учтен при корректировке контента',
        comment: 'Учесть комментарий',
        courseLanguage: 'Язык курса',
        stopGeneration: 'Остановить генерацию',
        replace: 'Заменить',
        lessonOutline: 'План урока',
        generateLessonContent: 'Создать урок',
        tryAgain: 'Перегенерировать',
        courseNameTitle: 'Название курса',
        courseDescriptionTitle: 'Описание курса',
        courseDescription: 'Напишите, о чем будет ваш курс?',
        courseDescriptionPlaceholder: 'Расскажите, о чем будет ваш курс',
        courseName: 'Введите название курса',
        courseNamePlaceholder: 'Как называется ваш курс?',
        courseDuration: 'На сколько часов должен быть ваш курс?',
        continue: 'Продолжить',
        addNewLesson: 'Добавить новый урок',
        lesson: 'Урок',
        generateLessonOutline: 'Создать план урока',
        hours: 'часов',
        addCourseInformation: 'Добавить информацию о курсе',
        editCourse: 'Редактирование курса',
        generateLessons: 'Генерация уроков',
        goToStart: 'Перейти к началу',
        makeShorter: 'Сделать короче',
        makeLonger: "Сделать длиннее",
        regenerate: 'Перегенерировать',
        completeAndSavePdf: 'Завершить и сохранить pdf',
        returnToHomepage: 'Вернуться на главную',
        whoIsYourAudience: 'Напишите кто ваша аудитория?',
        audiencePlaceholder: 'Расскажите о целевой аудитории курса',
        cancel: 'Вернуться к предыдущему',
        enterYourText: 'Введите ваш текст',
        goBack: 'Вернуться назад',
        courseDurationPdf: 'Продолжительность курса',
        lessonDurationPdf: 'Продолжительность урока',
        addOutline: 'Добавить пункт',
    },
    uk: {
        metaminderRegistered: 'Зареєстровані в Metaminder?',
        metaminderRegisteredDescription: 'Вже зареєстровані в Metaminder LMS як власник і ваш обліковий запис підтверджено? Тоді просто натисніть на кнопку авторизації нижче.',
        modalSignInTitle: 'Зареєструйтесь або увійдіть',
        modalSignInCaption_1: 'Щоб зберегти всі свої курси та редагувати',
        modalSignInCaption_2: 'Щоб розмістити свій курс на платформі MetaMinder та навчати студентів',
        modalSignInCaption_3: 'Щоб створити чат-бота та додати його до свого курсу',
        unsplash_by_using_images_from_unsplash_you_agree_to_their: "Використовуючи зображення з Unsplash, ви погоджуєтеся з їх",
        unsplash_license: "Ліцензією",
        unsplash_and: "і",
        unsplash_terms_of_service: "Умовами Надання Послуг",
        goTo: 'Перейти',
        courseSuccessfulExported: 'Курс успішно експортовано',
        goToCourse: 'Досліджуйте свій курс на MetaMinder',
        courseExported: 'Курс експортовано',
        metaminderInstructionExport_1: 'Зареєструйтесь на платформі MetaMinder',
        metaminderInstructionExport_2: 'Підтвердіть свій email',
        metaminderInstructionExport_3: 'Після реєстрації створіть компанію. Тільки в цьому випадку експорт буде доступний',
        aboutMM: 'Про Metaminder',
        registrationClue: 'Зареєструйтесь в меню праворуч зверху, щоб зберігати свої курси та створювати АІ-чат-бот на основі курсу!',
        websiteMM: 'Веб-сайт Metaminder',
        registrationMM: 'Реєстрація Metaminder',
        whatIsMetaminder: 'Що таке MetaMinder?',
        metaminderDescription: 'Не знаєте, як кодувати? Не хвилюйтеся. Наш Course Builder дозволяє створювати курс, який ідеально підходить як для браузерів, так і для мобільних додатків, без необхідності в будь-яких попередніх знаннях з кодування.',
        metaminderInstruction_1: 'Зареєструйтеся на платформі',
        metaminderInstruction_2: 'Заповніть інформацію про вашу компанію або досвід',
        metaminderInstruction_3: 'Експортуйте курс з конструктора AI на платформу MetaMinder одним натисканням',
        metaminderInstruction_4: 'Редагуйте курси на платформі, додавайте до них ігри, домашні завдання та тести',
        metaminderInstruction_5: 'Запрошуйте студентів на курс',
        metaminderInfo: 'Metaminder інформація',
        more: 'Більше',
        metaminder: 'Metaminder',
        metaminderBanner: ' - це ваша платформа для розміщення курсів',
        exportToMetaminder: 'Експортувати в Metaminder',
        tryNow: 'Спробувати зараз',
        myCourses: 'Мої курси',
        burgerSignIbSignUp: 'Вхід / Реєстрація',
        findPhoto: 'Знайти фото',
        on: 'на',
        emptyListPhotos: 'Зображення не знайдені, спробуйте ввести інший запит',
        download: 'Завантажити',
        lessonArray: ['урок', 'урока', 'уроків'],
        photos: 'Фотографії',
        enterQuery: 'Введіть запит',
        delete: 'Видалити',
        loadMore: 'Завантажити ще',
        courseSavedSuccessfully: 'Курс успішно збережено',
        not_valid_login_data: 'Невірний email або пароль',
        addImage: 'Додати зображення',
        emptyListCourses: 'Курси не знайдені, спробуйте ввести інший запит',
        searchCoursePlaceholder: 'Пошук по курсам',
        saveChanges: 'Зберегти зміни',
        chatBotInfo_1: 'Перейдіть до моїх курсів за допомогою меню.',
        chatBotInfo_2: 'Натисніть на кнопку Чат-бот.',
        chatBotInfo_3: 'Поділіться посиланням на чат-бота зі своїми учнями або встановіть його собі на сайт.',
        chatBotInfoTitle: 'Вже створили курс? Тоді встановіть чат-бота',
        chatBotInfo: 'Чат-бот / інформація',
        apiKey: 'Ключ API',
        copyApiKey: 'Скопіюйте цей ключ API та замініть його у скрипті на своєму сайті',
        script: 'Скрипт',
        copyChatbotScript: 'Скопіюйте цей скрипт і вставте його на свій сайт',
        tryChatBot: 'Спробуйте чат-бота зараз',
        chatBotDescription: 'Тепер ви можете спілкуватись з чат-ботом за своїм курсом. Спробуйте його прямо зараз за посиланням нижче (і поділіться зі своїми учнями). А якщо у вас є веб-сайт для розміщення курсу, слідуйте інструкціям, щоб швидко встановити бота на свій сайт!',
        chatBotTitle: 'Спробуйте чат-бот за своїм курсом!',
        chatBot: 'Чат-бот',
        goToBot: 'Metadialog бот',
        createNewCourse: 'Створити новий курс',
        success: 'Успішно',
        recentChanges: 'Останні зміни',
        cancelButton: 'Скасувати',
        next: 'Далі',
        guestDescription: 'Якщо ви увійдете як гість, ви не зможете зберегти свої курси',
        logIn: 'Увійти',
        logOut: 'Вийти',
        slideGuidCaption_1: 'Заповніть інформацію про курс',
        slideGuidCaption_2: 'Створіть структуру курсу з AI',
        slideGuidCaption_3: 'Створіть вміст уроку з AI',
        slideGuidCaption_4: 'Створіть тести на основі матеріалу уроку',
        slideGuidCaption_5: 'Ваш курс готовий, ви можете завантажити його у форматі PDF',
        emailRequired: 'Електронна пошта обов\'язкова',
        passwordRequired: 'Пароль обов\'язковий',
        confirmPasswordRequired: 'Підтвердження пароля обов\'язкове',
        user_exists: 'Користувач з таким email вже існує',
        passwordsNotMatch: 'Паролі не співпадають',
        passwordLengthError: 'Пароль повинен містити не менше 8 символів',
        notValidEmail: 'Невірний формат електронної пошти',
        policyAuth_1: 'Продовжуючи, ви погоджуєтеся з нашими',
        policyAuth_2: 'Умовами обслуговування',
        policyAuth_3: 'і підтверджуєте, що ви прочитали',
        policyAuth_4: 'Політику конфіденційності',
        policyAuth_5: 'щоб дізнатися, як ми збираємо, використовуємо і передаємо ваші дані.',
        signIn: 'Увійти',
        continueAsGuest: 'Продовжити як гість',
        signUp: 'Зареєструватися',
        conformPassword: 'Підтвердіть пароль',
        enterPassword: 'Введіть пароль',
        enterEmail: 'Введіть електронну пошту',
        howManyLessons: 'Виберіть мінімальну кількість уроків',
        example: 'Приклад',
        minutesArray: ['хвилина', 'хвилини', 'хвилин'],
        hoursArray: ['година', 'години', 'годин'],
        save: 'Зберегти',
        sendSusses: 'Дякую за ваш відгук! Ми розглянемо його та надамо відповідь протягом 48 годин.',
        send: 'Надіслати',
        sendUsMessage: 'Надішліть нам повідомлення',
        helpCenterDDescription: 'Якщо у вас виникли питання, ви можете звернутися до нашого центру підтримки.',
        fullName: 'Повне ім\'я',
        yourEmail: 'Ваша електронна адреса',
        subjectOfYourRequest: 'Тема вашого запиту',
        describeYourSubject: 'Опишіть вашу тему',
        describeProblem: 'Опишіть проблему',
        yourMessage: 'Ваше повідомлення',
        termsOfUse: 'Умови використання',
        privacyPolicy: 'Політика конфіденційності',
        helpCenter: 'Техпідтримка',
        language: 'Мова',
        deleteTest: 'Видалити тест',
        pickCorrectAnswer: 'Виберіть правильну відповідь',
        clueCourseTopic: 'Як приготувати неаполітанську піцу вдома.',
        clueCourseAudience: 'Жінки 25-30 років, без професійної кулінарної освіти.',
        clueCourseDescription: 'Результат проходження курсу: учні навчаться готувати 3 варіанти неаполітанської піци у звичайній духовці просто у себе вдома. \nКурс має містити такі теми, але не обмежуватися ними: Історія піци, особливості неаполітанської піци. \nКурс має містити практичні поради, рекомендації та домашні завдання.',
        questionArray: ['питання', 'питання', 'питань'],
        duration: 'Тривалість',
        quiz: 'Тест',
        updateTest: 'Оновити тест',
        complete: 'Завершити',
        addAnswer: 'Додати відповідь',
        generateTest: 'Створити тест',
        numberOfQuestion: 'Кількість питань',
        SINGLE_CHOICE: 'Один варіант відповіді',
        MULTI_CHOICE: 'Кілька варіантів відповіді',
        quizForLesson: 'Тест для уроку',
        addTest: 'Додати тест',
        lessonClue: 'Виділіть текст, який бажаєте перегенерувати',
        courseLinkTitle: 'Переходьте за посиланням для \nстворення нових курсів',
        courseLink: 'AI конструктор курсів',
        coursePlan: 'План курсу',
        buttonCancel: 'Скасувати',
        apply: 'Застосувати',
        regenerateCommentPlaceholder: 'Наприклад: Дайте реальні приклади в уроці успішних дизайнерських рішень для компаній, таких як Apple, Amazon і т.д.',
        regenerateCommentTitle: 'Напишіть коментар, який має бути врахований при коригуванні контенту',
        comment: 'Врахувати коментар',
        courseLanguage: 'Мова курсу',
        stopGeneration: 'Зупинити генерацію',
        replace: 'Замінити',
        lessonOutline: 'План урока',
        generateLessonContent: 'Створити урок',
        tryAgain: 'Перегенерувати',
        courseNameTitle: 'Назва курсу',
        courseDescriptionTitle: 'Опис курсу',
        courseDescription: 'Напишіть, про що буде ваш курс?',
        courseDescriptionPlaceholder: 'Розкажіть, про що буде ваш курс',
        courseName: 'Введіть назву курсу',
        courseNamePlaceholder: 'Як називається ваш курс?',
        courseDuration: 'На скільки годин має бути ваш курс?',
        continue: 'Продовжити',
        addNewLesson: 'Додати новий урок',
        lesson: 'Урок',
        generateLessonOutline: 'Створити план уроку',
        hours: 'годин',
        addCourseInformation: 'Додайте інформацію про курс',
        editCourse: 'Редагування курсу',
        generateLessons: 'Генерація уроків',
        goToStart: 'Перейти до початку',
        makeShorter: 'Зробити коротшим',
        makeLonger: "Зробити довшим",
        regenerate: 'Перегенерувати',
        completeAndSavePdf: 'Завершити і зберегти pdf',
        returnToHomepage: 'Повернутись на головну',
        whoIsYourAudience: 'Напишіть хто ваша аудиторія?',
        audiencePlaceholder: 'Розкажіть про цільову аудиторію курсу',
        cancel: 'Повернутись до попереднього',
        enterYourText: 'Введіть ваш текст',
        goBack: 'Повернутись назад',
        courseDurationPdf: 'Тривалість курсу',
        lessonDurationPdf: 'Тривалість уроку',
        addOutline: 'Додати пункт',
    },
    ar: {
        replace: 'استبدال',
        lessonOutline: 'مخطط الدرس',
        generateLessonContent: 'إنشاء المقرر الدراسي',
        tryAgain: 'المحاولة مرة أُخرى',
        courseNameTitle: 'اسم الدورة التعليمية',
        courseDescriptionTitle: 'وصف عنوان الدورة التعليمية',
        courseDescription: 'وصف عام لمحتوى الدورة التعليمية ؟',
        courseName: 'أدخل اسم الدورة',
        courseNamePlaceholder: 'على سبيل المثال: مصمم مبتدئ',
        courseDuration: 'كم ساعة يجب أن تكون مدة الدورة ؟',
        courseDurationPlaceholder: 'على سبيل المثال: 80 ساعة',
        continue: 'متابعة',
        addNewLesson: 'أضف مقرر دراسي جديد',
        lesson: 'الدرس',
        generateLessonOutline: 'إنشاء مخطط الدروس',
        hours: 'ساعات',
        addCourseInformation: 'أضف معلومات حول الدورة',
        editCourse: 'حرر الدورة التعليمية',
        generateLessons: 'إنشاء الدروس',
        goToStart: 'انقر على "اذهب" للبدء',
        makeShorter: 'اجعلهُ أقصر',
        makeLonger: 'اجعلهُ أطول',
        regenerate: 'تجديد',
        completeAndSavePdf: 'إتمام وحفظ الملف بصيغة pdf',
        returnToHomepage: 'العودة إلى الصفحة الرئيسية',
        whoIsYourAudience: 'اكتب من هي الفئة المستهدفة ؟',
        audiencePlaceholder: 'على سبيل المثال: المصممون المبتدئون',
        cancel: 'العودة إلى ماسبق',
        enterYourText: 'أدخل النص الخاص بك',
        goBack: 'العودة إلى الوراء',
        courseDurationPdf: 'مدة الدورة',
        addOutline: 'إضافة الخطوط العريضة',
        metaminderRegistered: 'مُسَجّل كَعُضْوٍ في Metaminder؟',
        metaminderRegisteredDescription: 'تم التسجيل بالفعل في Metaminder LMS كمالك وتم التحقق من حسابك ؟',
        modalSignInTitle: 'إنشاء حساب أو تسجيل الدخول',
        modalSignInCaption_1: 'لحفظ جميع دوراتك التعليمية وتحريرها',
        modalSignInCaption_2: 'لوضع دورتك التعليمية على منصة MetaMinder وتعليم الطلاب',
        modalSignInCaption_3: 'لإنشاء Chatbot وإضافته إلى الدورة التعليمية الخاصة بك',
        unsplash_by_using_images_from_unsplash_you_agree_to_their: 'باستخدام صور من Unsplash، فإنك توافق على صورهم',
        unsplash_license: 'رخصة',
        unsplash_and: 'و',
        unsplash_terms_of_service: 'شروط الخدمة',
        goTo: 'اذهب',
        courseSuccessfulExported: 'تم تصدير الدورة بنجاح',
        goToCourse: 'استكشف دورتك التعليمية على MetaMinder',
        courseExported: 'تم تصدير الدورة التعليمية ',
        metaminderInstructionExport_1: 'قُم بالتسجيل على Metaminder',
        metaminderInstructionExport_2: 'تأكيد بريدك الإلكتروني',
        metaminderInstructionExport_3: 'بعد التسجيل، قم بإنشاء شركة. في هذه الحالة فقط سيكون التصدير والنشر متاحاً',
        aboutMM: 'حول Metaminder',
        registrationClue: 'قم بالتسجيل في القائمة في أعلى اليمين من أجل حفظ دوراتك وإنشاء روبوت دردشة بالذكاء الاصطناعي بناءً على الدورة!',
        websiteMM: 'موقع Metaminder',
        registrationMM: 'التسجيل في Metaminder',
        whatIsMetaminder: 'ماهو MetaMinder ؟',
        metaminderDescription: 'لا تعرف كيفية الترميز أو كتابة الشيفرة ؟ لا تقلق. يتيح لك منشئ الدورة  لدينا إنشاء دورة تعليمية مناسبة تماماً لكل من المتصفحات وتطبيق الهاتف المحمول دون الحاجة إلى أي معرفة مسبقة بالترميز. ',
        metaminderInstruction_1: 'التسجيل على المنصة',
        metaminderInstruction_2: 'املأ معلومات حول شركتك أو خبراتك',
        metaminderInstruction_3: 'صدّر الدورة من منشئ دورة الذكاء الاصطناعي إلى منصة MetaMinder بنقرة واحدة',
        metaminderInstruction_4: 'تحرير الدورات على المنصة وإضافة الألعاب والواجبات المنزلية والاختبارات إليها',
        metaminderInstruction_5: 'دعوة الطلاب إلى الدورة',
        metaminderInfo: 'معلوماتMetaminder',
        more: 'المزيد',
        metaminder: 'Metaminder',
        metaminderBanner: 'هو منصة استضافتك لدوراتك التعليمية،',
        exportToMetaminder: 'تصدير إلى Metaminder',
        tryNow: 'حاول الآن',
        myCourses: 'دوراتي التعليمية',
        burgerSignIbSignUp: 'تسجيل الدخول/إنشاء حساب',
        findPhoto: 'ابحث عن الصورة',
        on: 'على',
        emptyListPhotos: 'لم يتم العثور على صور، حاول إدخال اِستفسار آخر',
        download: 'تنزيل',
        lessonArray: ['درس', 'دروس', 'دروس'],
        photos: 'صور',
        enterQuery: 'أدخل استفساراً',
        delete: 'حذف',
        loadMore: 'تحميل المزيد',
        courseSavedSuccessfully: 'تم حفظ الدورة التعليمية بنجاح',
        not_valid_login_data: 'البريد إلكتروني أو كلمة مرور غير صالحة',
        addImage: 'أضف صورة',
        emptyListCourses: 'لم يتم العثور على دورات، حاول إدخال اِستفسارآخر',
        searchCoursePlaceholder: 'البحث عن دورات',
        saveChanges: 'حفظ التغييرات',
        chatBotInfo_1: 'اذهب إلى دوراتي باستخدام القائمة.',
        chatBotInfo_2: 'انقر فوق زر Chatbot.',
        chatBotInfo_3: 'شارك الرابط إلى روبوت الدردشة مع طلابك أو قم بتثبيته على موقع الويب الخاص بك.',
        chatBotInfoTitle: 'هل أنشأت دورتك التعليمية بالفعل ؟ ثم قم بتثبيت روبوت الدردشة',
        chatBotInfo: 'روبوت دردشة/معلومات',
        apiKey: 'مفتاح API',
        copyApiKey: 'نسخ مفتاح واجهة برمجة التطبيقات هذا واستبدله بالنص الموجود على موقعك',
        script: 'النص',
        copyChatbotScript: 'انسخ هذا النص والصقه في موقعك',
        tryChatBot: 'جرب روبوت الدردشة الآن',
        chatBotDescription: 'إذا كنت قد قمت بالفعل ببناء دورة في منشئ الدورة، فيمكنك دمج روبوت الدردشة لطلابك مع محتوى الدورة فيه. إذا كان لديك موقع ويب لاستضافة الدورة التعليمية الخاصة بك، اتبع التعليمات لتثبيت الروبوت بسرعة على موقعك!',
        chatBotTitle: 'هل أنشأت الدورة التعليمية الخاصة بك بالفعل ؟ ثم قم بتثبيت روبوت الدردشة',
        chatBot: 'روبوت الدردشة',
        goToBot: 'روبوت Metadialog',
        createNewCourse: 'أنشئ دورة جديدة',
        success: 'نجاح',
        recentChanges: 'آخر التغييرات',
        cancelButton: 'إلغاء',
        next: 'التالي',
        guestDescription: 'إذا قمت بتسجيل الدخول كضيف فلن تتمكن من حفظ الدورات الخاصة بك',
        logIn: 'تسجيل الدخول',
        logOut: 'تسجيل الخروج',
        slideGuidCaption_1: 'املأ معلومات الدورة',
        slideGuidCaption_2: 'إنشاء أساس دورة تعليمية مع الذكاء الاصطناعي',
        slideGuidCaption_3: 'إنشاء المقرر الدراسي باستخدام الذكاء الاصطناعي',
        slideGuidCaption_4: 'قم بإنشاء اختبارات بناءً على مادة الدرس',
        slideGuidCaption_5: 'الدورة التعليمية الخاصة بك جاهزة، يمكنك تنزيلها بصيغة PDF',
        emailRequired: 'البريد الإلكتروني مطلوب',
        passwordRequired: 'كلمة المرور مطلوبة',
        confirmPasswordRequired: 'تأكيد كلمة المرور',
        user_exist: 'المستخدم مع هذا البريد الإلكتروني موجود سابقاً',
        passwordsNotMatch: 'كلمات المرور لا تتطابق',
        passwordLengthError: 'يجب أن يكون طول كلمة المرور 8 أحرف على الأقل',
        notValidEmail: 'البريد الإلكتروني غير صالح',
        policyAuth_1: 'من خلال المتابعة، أنت توافق على ',
        policyAuth_2: 'شروط الخدمة',
        policyAuth_3: 'وتُأكد بأنك قرأت ',
        policyAuth_4: 'سياسة الخصوصية',
        policyAuth_5: 'لمعرفة كيفية جمع واستخدام ومشاركة بياناتك.',
        signIn: 'تسجيل الدخول',
        continueAsGuest: 'المتابعة كضيف',
        signUp: 'إنشاء حساب',
        conformPassword: 'تأكيد كلمة المرور',
        enterPassword: 'أدخل كلمة المرور',
        enterEmail: 'أدخل عنوان البريد الإلكتروني',
        howManyLessons: 'اختر الحد الأدنى من الدروس',
        example: 'مثال',
        minutesArray: ['دقيقة', 'دقائق', 'دقائق'],
        hoursArray: ['ساعة', 'ساعات', 'ساعات'],
        save: 'حفظ',
        sendSusses: 'شكراً لك على ملاحظاتك سنقوم بمراجعتها والعودة إليك في غضون 48 ساعة.',
        send: 'إرسال',
        sendUsMessage: 'أرسل لنا رسالة',
        helpCenterDDescription: 'قم بوصف مشكلتك أو سؤالك وسيتواصل فريقنا معك عبر البريد الإلكتروني.',
        fullName: 'الاسم الكامل',
        yourEmail: 'بريدك الإلكتروني',
        subjectOfYourRequest: 'موضوع طلبك',
        describeYourSubject: 'وصف موضوعك',
        describeProblem: 'وصف مشكلتك',
        yourMessage: 'رسالتك تذهب هنا...',
        termsOfUse: 'شروط الاستخدام',
        privacyPolicy: 'سياسة الخصوصية',
        helpCenter: 'مركز المساعدة',
        language: 'اللغة',
        deleteTest: 'حذف الاختبار',
        pickCorrectAnswer: 'اختر الإجابة الصحيحة',
        clueCourseTopic: 'كيف تصنع بيتزا نابولي في المنزل.',
        clueCourseAudience: 'النساء اللواتي تتراوح أعمارهن بين 25 و 30 سنة، دون تعليم مهني إزاء الطهي.',
        clueCourseDescription: 'نتيجة الدورة: سيتعلم الطلاب كيفية طهي ثلاثة أنواع من بيتزا نابولي في فرن تقليدي في المنزل  ./يجب أن تتضمن الدورة على سبيل المثال لا الحصر الموضوعات التالية: تاريخ البيتزا، خصوصيات بيتزا نابولي. يجب أن تتضمن الدورة نصائح عملية ومعلومات وواجبات منزلية.',
        questionArray: ['سؤال', 'أسئلة', 'أسئلة'],
        duration: 'المدة',
        quiz: 'اختبار',
        updateTest: 'تعديل الاختبار',
        complete: 'إتمام',
        addAnswer: 'إضافة إجابة',
        generateTest: 'إنشاء اختبار',
        numberOfQuestion: 'عدد الأسئلة',
        SINGLE_CHOICE: 'خيار واحد',
        MULTI_CHOICE: 'الاختيار من متعدد',
        quizForLesson: 'اختبار حول الدرس',
        addTest: 'أضف الاختبار',
        lessonClue: 'حدد النص الذي تريد تجديده',
        courseLinkTitle: 'اتبع الرابط لإنشاء دورات جديدة',
        courseLink: 'منشئ دورة الذكاء الاصطناعي',
        coursePlan: 'خطة الدورة التعليمية',
        buttonCancel: 'إلغاء',
        apply: 'تَقَدَّمَ بـطَلَب',
        regenerateCommentPlaceholder: 'على سبيل المثال: قدم أمثلة واقعية خلال دورة حلول التصميم الناجحة لشركات مثل Apple و Amazon وغيرها من الشركات.',
        regenerateCommentTitle: 'اكتب تعليقًا يجب أخذه بعين الاعتبار  لتصحيح المحتوى',
        comment: 'ضع في اعتبارك التعليق',
        courseLanguage: 'لغة الدورة',
        stopGeneration: 'أوقف الإنشاء',
        courseDescriptionPlaceholder: 'وصف عام لمحتوى الدورة التعليمية ؟',
        lessonDurationPdf: 'مدة الدرس',
    },
}
