export const COLORS = {
    dark: {
        notification: '#000000',
        title: '#FFFFFF', // Main3
        text: '#384868', // done - Main2 - #F6F7F8
        inactiveText: '#B6C5E1', // done
        invertText: '#7D8FB2', // Background_Main
        buttonText: '#FFFFFF', // done - Background_Main
        icon: '#FFFFFF', // - done - Main3
        invertIcon: '#000000', //
        inactiveIcon: '#7D8FB3', // done - Secondary4
        primary: '#42FE73', // Main1
        primarySecondary: '#0D8BDD', // Main1
        background: '#F8F9FD', // Background_Main
        card: '#FFFFFF', // dark
        header: '#131F38', // dark
        error: '#FF7979', // - Secondary.7
        success: '#0FCFAC',
        border: '#C9D6F0',
        body: '#9198A5',
        inactiveButton: '#384868',
        pageBackground: '#DDE6F7',
        primaryToast: '#2E4FFF',
        primaryLight: '#4A7DFF',
        bannerBg: '#6579A1',
    },
};
